const staging = {
  GRAPHQL_URL: "/query",
  //default public token
  MAPBOX_TOKEN:
    "pk.eyJ1Ijoiam9oYW5uYS1uZXRub2QiLCJhIjoiY2t4OTBxNHZuMGxybjJwcWNrcHUya3JuNCJ9.KTDkpxO4L_8kfCkIMZHntg",
};

const production = {
  GRAPHQL_URL: "https://dns-sitemap.netnod.se/query",
  //prod specific token
  MAPBOX_TOKEN:
    "pk.eyJ1Ijoiam9oYW5uYS1uZXRub2QiLCJhIjoiY2t1emlmNzY0MTA5eDJucXd6NW5ibHo0aSJ9.HYsRJHqwJ5zGc26i60VqDw",
};

export const EnvConfig = {
  ...(process.env.REACT_APP_ENVIRONMENT === "local" && {
    ...staging,
  }),
  ...(process.env.REACT_APP_ENVIRONMENT === "staging" && {
    ...staging,
  }),
  ...(process.env.REACT_APP_ENVIRONMENT === "production" && {
    ...production,
  }),
};
