import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { EnvConfig } from "./EnvConfig.js";
import "mapbox-gl/dist/mapbox-gl.css";

import { Map } from "./components/Map";

const client = new ApolloClient({
  // default to production, provided in dev env
  uri: EnvConfig.GRAPHQL_URL,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Map />
    </ApolloProvider>
  );
}

export default App;
